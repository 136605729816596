import React from 'react';
import PropTypes from 'prop-types';
import ImageScreenshotFaceFinder from './ImageScreenshotFaceFinder';
import ImageScreenshotMapoholic from './ImageScreenshotMapoholic';
import ImageScreenshotOSS from './ImageScreenshotOSS';
import ImageScreenshotSlugSnail from './ImageScreenshotSlugSnail';

const ImageScreenshot = ({ title }) => {
  switch (title) {
    case 'face-finder':
      return <ImageScreenshotFaceFinder />;
    case 'mapoholic':
      return <ImageScreenshotMapoholic />;
    case 'oxford-street-o-series':
      return <ImageScreenshotOSS />;
    case 'slug-and-snail-stories':
      return <ImageScreenshotSlugSnail />;
    default:
      return <p>*** screenshot missing ***</p>;
  }
};

ImageScreenshot.propTypes = {
  title: PropTypes.string,
};
ImageScreenshot.defaultProps = {
  title: 'placeholder',
};

export default ImageScreenshot;
