import React, { Component } from 'react';
import { Link } from 'gatsby';

import ContactForm from '../components/ContactForm';
import ContactLinks from '../components/ContactLinks';
import GlideScroll from '../components/GlideScroll';
import ImageMark from '../components/ImageMark';
import Layout from '../components/Layout';
import Project from '../components/Project';
import SEO from '../components/SEO';
import {
  iconBook,
  iconCompass,
  iconMap,
  iconPerson, // for FaceFinder when added
} from '../resources/icons';

// separate detailed project content from structure
const projectDetails = [{
  title: 'MapOholic', // id = title.toLowerCase(), image = <Image[title]Screenshot />
  icon: iconMap,
  source: [{
    label: {
      en: 'code (client)',
      cs: 'kód (klient)',
    },
    url: 'https://github.com/markcollis/mapoholic-client',
  }, {
    label: {
      en: 'code (server)',
      cs: 'kód (server)',
    },
    url: 'https://github.com/markcollis/mapoholic-server',
  }], // link to Github (array to support multiple sources per project)
  live: {
    label: {
      en: 'live site',
      cs: 'živé stránky',
    },
    url: 'https://mapoholic.markcollis.dev',
  }, // link to live site (only one per project)
  description: {
    en: `<p>MapOholic is my largest recent project and I am an active user as well as
    its developer. It is an online database of orienteering maps, together with routes,
    detailed information about the the races and cross-references to other
    users, events and clubs.</p>`,
    cs: `<p>MapOholic je můj největší nedávný projekt a jsem aktivní uživatel i jeho
    vývojář. Je to online databáze map orientačního běhu spolu s trasami, podrobnými
    informacemi o závodech a křížovými odkazy na ostatní uživatele, akce a kluby.</p>`,
  }, // short description, provide as HTML with <p> tags
  requirement: {
    en: `<p>I have a large collection of <a href="https://www.google.com/search?q=orienteering+maps&amp;tbm=isch"
    target="_blank" rel="noopener noreferrer" class="section-projects__inline-link">orienteering maps</a> from
    more than 20 years of competing regularly in the sport. These are neatly filed away in folders but such a
    library isn't easy to search, doesn't have much other information about the events and can't be shared easily
    with other people.</p>
    <p>I wanted to move to an interactive online map collection, so set out some key requirements and started
    to develop one. Firstly, it needed to be multi-user to enable people to share their routes, make comments
    and exploit common background information about events. Secondly, while the focus of the application is on
    scanned maps, each of these could be associated with routes (e.g. from GPS tracking), course details, results,
    and so on. Thirdly, the collection needed to be easily searchable in a variety of ways, including on an
    overview map of all event locations. Finally, for Czech events there is an API providing access to some event
    information and results which could be exploited to quickly and accurately populate relevant fields.</p>`,
    cs: `<p>Mám velkou sbírku <a href="https://www.google.com/search?q=orienteering+maps&amp;tbm=isch"
    target="_blank" rel="noopener noreferrer" class="section-projects__inline-link">map pro orientační běh</a> z
    více než 20 let pravidelné soutěže ve sportu. Ty jsou úhledně uloženy ve složkách, ale taková knihovna
    není snadno prohledávána, nemá mnoho dalších informací o událostech a nemůže být snadno sdílena
    s ostatními lidmi.</p>
    <p>Chtěl jsem přejít na interaktivní sbírku map online, takže jsem stanovil některé klíčové požadavky a
    začal je rozvíjet. Zaprvé muselo být víceuživatelské, aby lidé mohli sdílet své trasy, komentovat a využívat
    společné základní informace o událostech. Zadruhé, zatímco je aplikace zaměřena na naskenované mapy, každá
    z nich by mohla být spojena s trasami (např. Ze sledování GPS), podrobnostmi o kurzu, výsledky atd.
    Za třetí, kolekce musela být snadno prohledatelná různými způsoby, včetně přehledové mapy všech míst událostí.
    A konečně, pro české události existuje API, které poskytuje přístup k některým informacím a
    výsledkům události, které by mohly být využity k rychlému a přesnému naplnění příslušných polí.</p>`,
  }, // short description, provide as HTML with <p> tags
  solution: {
    en: `<p>MapOholic was developed from scratch as a modern SPA to meet my requirement. It has separate front-end
    and back-end components, both written in JavaScript, which communicate using a REST API.</p>
    <p>The front-end user interface is based on React and Redux. Other packages used include Leaflet (location maps),
    Formik (forms) and LinguiJS (i18n).</p>
    <p>The back-end is based on Node.js and Express to implement the REST API for managing users, events and
    maps with MongoDB used to store the data. For certain functions the back-end reaches back to another,
    external, API for event data.</p>`,
    cs: `<p>MapOholic byl vyvinut od nuly jako moderní SPA, aby vyhovoval mým požadavkům. Má samostatné
    komponenty front-end a back-end, oba napsané v JavaScriptu, které komunikují pomocí REST API.</p>
    <p>Uživatelské rozhraní front-end je založeno na React a Redux. Mezi další použité balíčky patří
    leták (mapy umístění), Formik (formuláře) a LinguiJS (i18n).</p>
    <p>Back-end je založen na Node.js a Express k implementaci REST API pro správu uživatelů, událostí
    a map s MongoDB používaným k ukládání dat. U některých funkcí back-end dosahuje zpět k jinému,
    externímu API pro data událostí.`,
  }, // short description, provide as HTML with <p> tags
}, {
  title: 'Slug and Snail Stories', // id = title.toLowerCase(), image = <Image[title]Screenshot />
  icon: iconBook,
  source: [{
    label: {
      en: 'code',
      cs: 'kód',
    },
    url: 'https://github.com/markcollis/slug-and-snail',
  }], // link to Github (array to support multiple sources)
  live: {
    label: {
      en: 'live site',
      cs: 'živé stránky',
    },
    url: 'https://slugandsnail.markcollis.dev',
  }, // link to live site
  description: {
    en: `<p>The Slug and Snail Stories site was developed as a landing page for a
    fictional business, to practice core HTML and CSS skills. <em>The stories that
    inspired it really do exist, but the only customers are my own children.</em></p>`,
    cs: `<p>Příběhy Šneka a Slimáka bylo vyvinuto jako vstupní stránka pro fiktivní
    firmu, kde si můžete procvičit základní dovednosti HTML a CSS. <em>Příběhy, které
    to inspirovaly, skutečně existují, ale jedinými zákazníky jsou mé vlastní děti.</em></p>`,
  }, // short description, provide as HTML with <p> tags
  requirement: {
    en: `<p>Telling stories has always been a great way to entertain children and
    stimulate their imagination. But not every parent is a natural storyteller.</p>
    <p>So why not make life easier for them by offering a storytelling service
    available as and when you want it... Before launching the service, my fictional
    customer wants a mock landing page for the business.</p>`,
    cs: `<p>Vyprávění příběhů bylo vždy skvělým způsobem, jak pobavit děti a stimulovat
    jejich fantazii. Ale ne každý rodič je přirozený vypravěč.</p>
    <p>Tak proč jim nezjednodušit život tím, že jim nabídnete službu vyprávění
    příběhů, kdykoli to budete chtít ... Před zahájením služby chce můj smyšlený
    zákazník falešnou vstupní stránku pro podnikání.</p>`,
  }, // short description, provide as HTML with <p> tags
  solution: {
    en: `<p>The Slug and Snail stories landing page is a single page designed to quickly
    convey the key sales messages of the business and entice potential customers to
    engage further. It is mostly plain HTML and CSS and is simple and responsive.</p>`,
    cs: `<p>Vstupní stránka Příběhy Šneka a Slimáka je jediná stránka navržená tak, aby
    rychle zprostředkovala klíčové zprávy o prodeji firmy a přilákala potenciální zákazníky
    k dalšímu zapojení. Je to většinou prostý HTML a CSS a je jednoduchý a citlivý.</p>`,
  }, // short description, provide as HTML with <p> tags
}, {
  title: 'Face-Finder', // id = title.toLowerCase(), image = <Image[title]Screenshot />
  icon: iconPerson,
  source: [{
    label: {
      en: 'code',
      cs: 'kód',
    },
    url: 'https://github.com/markcollis/face-finder',
  }], // link to Github (array to support multiple sources)
  live: {
    label: {
      en: 'live site',
      cs: 'živé stránky',
    },
    url: 'https://face-finder.markcollis.dev',
  }, // link to live site
  description: {
    en: `<p>Face-finder analyses images either on the web or uploaded locally to detect
    faces (using the <a href="https://github.com/justadudewhohacks/face-api.js"
    target="_blank" rel="noopener noreferrer" class="section-projects__inline-link">face-api.js</a>
    library running in the browser). If faces are detected, they are overlaid on the original
    image and their coordinates can be shown.</p>`,
    cs: `<p>Vyhledávač obličejů analyzuje obrázky na webu nebo nahrané místně, aby detekoval
    tváře (pomocí knihovny <a href="https://github.com/justadudewhohacks/face-api.js"
    target="_blank" rel="noopener noreferrer" class="section-projects__inline-link">face-api.js</a>
    v prohlížeči). Pokud jsou detekovány tváře, jsou překryty na původním obrázku a mohou být
    zobrazeny jejich souřadnice.</p>`,
  }, // short description, provide as HTML with <p> tags
  requirement: {
    en: `<p>Face-finder is based on an interesting project within an online course.
    Although the basics of the original user interface have been retained, I wanted to rewrite
    it to use an open source face recognition library rather than a commercial API, and
    to expand and improve the way in which the results were presented.</p>`,
    cs: `<p>Face-finder je založen na zajímavém projektu v rámci online kurzu.
    Přestože byly zachovány základy původního uživatelského rozhraní, chtěl jsem jej přepsat
    tak, aby používal knihovnu pro rozpoznávání obličejů s otevřeným zdrojovým kódem než
    komerční API, a rozšířit a zlepšit způsob, jakým byly výsledky prezentovány.</p>`,
  }, // short description, provide as HTML with <p> tags
  solution: {
    en: `<p>The front end is based on React, making use of face-api.js for the number crunching.
    There is also a simple back end built using Node.js, Express and PostgreSQL that
    provides a simple API to register users and keep track of the number of images that
    they have analysed.</p>`,
    cs: `<p>Přední konec je založen na React, využívající face-api.js pro drcení čísla.
    K dispozici je také jednoduchý zadní konec vytvořený pomocí Node.js, Express a PostgreSQL,
    který poskytuje jednoduché API pro registraci uživatelů a sledování počtu obrázků, které analyzovali.</p>`,
  }, // short description, provide as HTML with <p> tags
}, {
  title: 'Oxford Street-O Series', // id = title.toLowerCase(), image = <Image[title]Screenshot />
  icon: iconCompass,
  source: [], // not open source
  live: {
    label: {
      en: 'live site',
      cs: 'živé stránky',
    },
    url: 'http://oss.markandblanka.com',
  }, // link to live site
  description: {
    en: `<p>For many years before pursuing web development full time, I developed a number of
    different websites either as personal projects or for clubs and societies of which
    I was a member. Most of these have been retired or replaced, but the site I created for
    the Oxford Street-O Series is still being actively used almost 10 years on.</p>`,
    cs: `<p>Po mnoho let, než jsem se věnoval vývoji webových stránek na plný úvazek, jsem vyvinul
    řadu různých webových stránek, buď jako osobní projekty, nebo pro kluby a společnosti,
    jejichž jsem byl členem. Většina z nich byla v důchodu nebo nahrazena, ale stránky,
    které jsem vytvořil pro Oxford Street-O Series, se stále aktivně využívají téměř 10 let.</p>`,
  }, // short description, provide as HTML with <p> tags
  requirement: {
    en: `<p>Soon after moving to Oxford, I came up with the idea of organising some evening
    orienteering training events around the city streets. In addition to planning the
    events themselves, I needed a website for publicity and to provide event details and
    results to competitors. If the idea was a success I would be recruiting other event
    organisers so it was important to support multiple content editors.</p>`,
    cs: `<p>Brzy poté, co jsem se přestěhoval do Oxfordu, jsem přišel s myšlenkou uspořádat
    některé večerní tréninkové akce v okolí městských ulic. Kromě plánování samotných akcí
    jsem potřeboval webovou stránku pro propagaci a poskytování podrobností a výsledků událostí
    konkurentům. Pokud by myšlenka byla úspěšná, najímal bych další organizátory událostí,
    takže bylo důležité podporovat více editorů obsahu.</p>`,
  }, // short description, provide as HTML with <p> tags
  solution: {
    en: `<p>The site I developed was based on a customised Wordpress template to provide
    support for an event calendar and results section consistent with expectations of
    the orienteering community. Both the site and race series are still active today.</p>`,
    cs: `<p>Web, který jsem vyvinul, byl založen na přizpůsobené šabloně Wordpress, která
    poskytuje podporu pro kalendář událostí a sekci výsledků v souladu s očekáváními
    komunity orientačního běhu. Jak web, tak série závodů jsou dodnes aktivní.</p>`,
  }, // short description, provide as HTML with <p> tags
}];

class IndexPage extends Component {
  state = {
    language: 'en', // default, will be set to cs automatically if selected on a previous visit
  }

  componentDidMount() { // load language if stored in a previous session
    const storedLanguage = window.localStorage.getItem('markcollisDevLanguage');
    // console.log('retrieved stored language:', storedLanguage);
    if (storedLanguage) this.setState({ language: storedLanguage });
  }

  setLanguage = (language) => {
    // console.log('setting language:', language);
    this.setState({ language });
    window.localStorage.setItem('markcollisDevLanguage', language);
  }

  render() {
    const { language } = this.state;
    return (
      <Layout language={language} setLanguage={this.setLanguage}>
        <SEO title="Home: Mark Collis" />
        <section className="section-title">
          <div className="container">
            <h1 className="section-title__main-title">Mark Collis</h1>
            <h2 className="section-title__profile">
              {(language === 'en')
                ? 'JavaScript developer. Find out more about me and my work below.'
                : 'JavaScriptový vývojář. Zjistěte více o mně a mé práci níže'}
            </h2>
            <GlideScroll target="about">
              <Link className="section-link section-link--dark" to="/#about">
                {(language === 'en') ? 'About Me' : 'O mně'}
              </Link>
            </GlideScroll>
          </div>
        </section>

        <section className="section-about" id="about">
          <div className="container">
            <div className="section-about__picture central">
              <ImageMark />
            </div>
            <div className="section-about__profile">
              <div className="row">
                <div className="column-66 central">
                  <h2>{(language === 'en') ? 'About Me' : 'O mňe'}</h2>
                  <p>
                    {(language === 'en')
                      ? `Welcome! I am a JavaScript developer based in Prague, Czech Republic. On this
                        page you can find out a bit more about me and some of the projects that I have
                        worked on.`
                      : `Vítejte! Jsem vývojář JavaScriptu se sídlem v Praze. Na toto stránce
                        můžete se učit více o mně a některých projektech, které jsem udělal.`}
                  </p>
                  <p>
                    {(language === 'en')
                      ? `Inspired by the impact that having easy access to vast amounts of information
                        and interactive services has had on the modern world, I enjoy developing tools
                        that provide effective access to these, whether for work, study or just fun.`
                      : `Inspirován účinkem, který měl snadný přístup k obrovskému množství informací
                        a interaktivních služeb na moderní svět, si užívám vývojové nástroje, které
                        k nim poskytují efektivní přístup, ať už pro práci, studium nebo jen pro zábavu.`}
                  </p>
                  <p>
                    {(language === 'en')
                      ? `Before becoming a developer, I worked as a technical consultant for over 10 years,
                        gaining experience of capturing, analysing and documenting requirements, project
                        scoping, planning, resourcing and delivery, options evaluation and procurement support,
                        and information security considerations. As a result I have a good understanding of
                        how software development supports wider business objectives and the importance of
                        close engagement with the user community.`
                      : `Předtím, než jsem se stal vývojářem, pracoval jsem více než 10 let jako technický
                        konzultant, získával jsem zkušenosti se získáváním, analyzováním a dokumentováním
                        požadavků, určování rozsahu projektu, plánování, zajišťování zdrojů a dodávek,
                        vyhodnocování možností a podporu při zadávání zakázek a otázky týkající se
                        bezpečnosti informací. Výsledkem je, že dobře rozumím tomu, jak vývoj softwaru
                        podporuje širší obchodní cíle a význam úzké spolupráce s uživatelskou komunitou.`
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <GlideScroll target="projects">
                <Link className="section-link section-link--light" to="/#projects">
                  {(language === 'en') ? 'Project Portfolio' : 'Portfolio Projektů'}
                </Link>
              </GlideScroll>
            </div>
          </div>
        </section>

        <section className="section-projects" id="projects">
          <div className="container">
            <div className="row">
              <div className="column-66 central">
                <div className="section-projects__introduction">
                  <h3>{(language === 'en') ? 'Project Portfolio' : 'Portfolio Projektů'}</h3>
                  <p>
                    {(language === 'en')
                      ? `Here are some examples of projects that I have developed. In each case,
                        I have described the high level requirement and summarised how I approached
                        developing a solution to meet it. There are also links to the live sites
                        and source code.`
                      : `Zde je několik příkladů projektů, které jsem vyvinul. V každém případě
                        jsem popsal požadavek na vysoké úrovni a shrnul, jak jsem přistoupil k vývoji
                        řešení, abych ho splnil. Existují také odkazy na živé weby a zdrojový kód.`}
                  </p>
                  <ul>
                    {projectDetails.map((project) => {
                      const titleLabel = project.title.replace(/\s+/g, '-').toLowerCase();
                      return (
                        <li key={titleLabel}>
                          <GlideScroll target={titleLabel}>
                            <Link className="section-projects__introduction-link" to={`/#${titleLabel}`}>
                              {project.icon}
                              {project.title}
                            </Link>
                          </GlideScroll>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-projects__list">
            {projectDetails.map((project) => {
              return <Project key={project.title} language={language} details={project} />;
            })}
          </div>
          <div className="row">
            <GlideScroll target="contact">
              <Link className="section-link section-link--dark" to="/#contact">
                {(language === 'en')
                  ? 'Contact Me'
                  : 'Kontaktujte mě'}
              </Link>
            </GlideScroll>
          </div>
        </section>

        <section className="section-contact" id="contact">
          <div className="container">
            <div className="row">
              <div className="column-66 central">
                <div className="section-contact__preamble">
                  <h3>
                    {(language === 'en')
                      ? 'Contact Me'
                      : 'Kontaktujte mě'}
                  </h3>
                  <p>
                    {(language === 'en')
                      ? `I have recently joined `
                      : `Nedávno jsem se připojil k `}
                    <a href="https://salsitasoft.com">Salsita Software</a>
                    {(language === 'en')
                      ? ` as a Full Stack Developer, so I am not currently looking for new opportunities.
                        But if you are interested in finding out more about me and my work, feel free to
                        get in touch by email or using the form below.`
                      : ` jako Vývojář Plného Zásobníku, a pak nehledám teď nové pracovní příležitosti.
                        Ale pokud máte zájem dozvědět se více o mně a mé práci, kontaktujte mě.`}
                  </p>
                </div>
                <div className="section-contact__form">
                  <ContactForm language={language} />
                </div>
              </div>
            </div>
          </div>
          <div className="row central">
            <div className="section-contact__links">
              <ContactLinks
                email="mark@markcollis.dev"
                github="markcollis"
                linkedin="mark-collis-prague"
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default IndexPage;
