import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// The ContactForm component renders a form that is converted to an email and sent by the server
class ContactForm extends Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
  }

  state = {
    name: '',
    email: '',
    message: '',
    mailSent: false,
    mailError: null,
  }

  handleInputChange = (field, e) => {
    this.setState({ [field]: e.target.value });
  }

  handleFormSubmit = (e) => {
    // console.log('submitting form:', this.state);
    this.setState({
      mailSent: false,
      mailError: null,
    });
    e.preventDefault();
    axios({
      method: 'post',
      url: 'api/contact.php',
      // url: 'http://localhost/api/contact.php',
      headers: { 'content-type': 'application/json' },
      data: this.state,
    }).then((response) => {
      const { sent } = response.data;
      // console.log('response:', response.data);
      this.setState({ mailSent: sent });
    }).catch((error) => {
      const { sent, message } = error.response.data;
      this.setState({
        mailSent: sent,
        mailError: message,
      });
      // console.log(('error', error));
      // console.log('response:', error.response.data);
    });
    // console.log('state in ContactForm:', this.state);
  }

  handleFormReset = (e) => {
    e.preventDefault();
    this.setState({
      name: '',
      email: '',
      message: '',
    });
  }

  render() {
    const { language } = this.props;
    const {
      name,
      email,
      message,
      mailSent,
      mailError,
    } = this.state;
    // console.log('Not yet tested on live server');
    // console.log(`mailSent: ${mailSent}`);
    if (mailError) console.log(`mailError: ${mailError}`);
    return (
      <form>
        <div className="section-contact__field section-contact__field--half-width-left">
          <label htmlFor="name">
            {(language === 'en')
              ? 'Name'
              : 'Jméno'}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={e => this.handleInputChange('name', e)}
          />
        </div>
        <div className="section-contact__field section-contact__field--half-width-right">
          <label htmlFor="email">
            {(language === 'en')
              ? 'Email Address'
              : 'Emailová Adresa'}
          </label>
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={e => this.handleInputChange('email', e)}
          />
        </div>
        <div className="section-contact__field">
          <label htmlFor="message">
            {(language === 'en')
              ? 'Message'
              : 'Zpráva'}
          </label>
          <textarea
            name="message"
            id="message"
            rows="4"
            value={message}
            onChange={e => this.handleInputChange('message', e)}
          />
        </div>
        {(language === 'en')
          ? (
            <>
              <span className="section-contact__button">
                <input
                  type="submit"
                  value="Send Message"
                  onClick={this.handleFormSubmit}
                />
              </span>
              <span className="section-contact__button">
                <input
                  type="reset"
                  value="Reset Form"
                  onClick={this.handleFormReset}
                />
              </span>
            </>
          )
          : (
            <>
              <span className="section-contact__button">
                <input
                  type="submit"
                  value="Poslat Zprávu"
                  onClick={this.handleFormSubmit}
                />
              </span>
              <span className="section-contact__button">
                <input
                  type="reset"
                  value="Resetovat Formulář"
                  onClick={this.handleFormReset}
                />
              </span>
            </>
          )}
        {(mailSent)
          ? (
            <div className="section-contact__field">
              <p>
                {(language === 'en')
                  ? 'Your message has been sent, thank you.'
                  : 'Zpráva poslaná, děkuji.'}
              </p>
            </div>
          )
          : ''}
      </form>
    );
  }
}

export default ContactForm;
