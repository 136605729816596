import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Expandable from './Expandable';
import ImageScreenshot from './ImageScreenshot';
import {
  iconGithub,
  iconLiveSite,
} from '../resources/icons';

const Project = ({ language, details }) => {
  if (!details) return null;
  const {
    title,
    source,
    live,
    description,
    requirement,
    solution,
  } = details;
  const titleLabel = title.replace(/\s+/g, '-').toLowerCase();
  return (
    <div className="section-projects__project" id={titleLabel}>
      <div className="container">
        <div className="row">
          <div className="column-40">
            <div className="section-projects__screenshot">
              <a
                href={live.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageScreenshot title={titleLabel} />
              </a>
            </div>
          </div>
          <div className="column-40 central-vertically">
            <div className="section-projects__description">
              <div>
                <h3>{title}</h3>
                {parse(description[language])}
                {(source && source.length > 0)
                  ? (
                    <p className="section-projects__project-link">
                      {iconGithub}
                      {source.map((eachSource) => {
                        return (
                          <a
                            key={eachSource.url}
                            href={eachSource.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {eachSource.label[language]}
                          </a>
                        );
                      })}
                    </p>
                  )
                  : null}
                {(live)
                  ? (
                    <p className="section-projects__project-link">
                      {iconLiveSite}
                      <a
                        href={live.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {live.label[language]}
                      </a>
                    </p>
                  )
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column-50">
            <div className="section-projects__requirement">
              <Expandable language={language}>
                <h4>{(language === 'en') ? 'Requirement:' : 'Potřeba:'}</h4>
                {parse(requirement[language])}
              </Expandable>
            </div>
          </div>
          <div className="column-50">
            <div className="section-projects__solution">
              <Expandable language={language}>
                <h4>{(language === 'en') ? 'Solution:' : 'Řešení:'}</h4>
                {parse(solution[language])}
              </Expandable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Project.propTypes = {
  language: PropTypes.string,
  details: PropTypes.objectOf(PropTypes.any),
};
Project.defaultProps = {
  language: 'en',
  details: null,
};

export default Project;
