import React from 'react';
import PropTypes from 'prop-types';

import {
  iconFacebook,
  iconGithub,
  iconLinkedIn,
  iconMail,
} from '../resources/icons';

// The ContactLinks component renders a set of contact details as links with appropriate icons
const ContactLinks = ({
  email,
  facebook,
  github,
  linkedin,
}) => {
  if (!email && !facebook && !github && !linkedin) return null; // nothing to show
  const liMail = (email)
    ? (
      <li>
        {iconMail}
        <a href={`mailto:${email}`}>{email}</a>
      </li>
    )
    : null;
  const liFacebook = (facebook)
    ? (
      <li>
        {iconFacebook}
        <a href={`https://facebook.com/${facebook}`}>{facebook}</a>
      </li>
    )
    : null;
  const liGithub = (github)
    ? (
      <li>
        {iconGithub}
        <a href={`https://github.com/${github}`}>{github}</a>
      </li>
    )
    : null;
  const liLinkedIn = (linkedin)
    ? (
      <li>
        {iconLinkedIn}
        <a href={`https://www.linkedin.com/in/${linkedin}`}>{linkedin}</a>
      </li>
    )
    : null;
  return (
    <ul>
      {liMail}
      {liGithub}
      {liFacebook}
      {liLinkedIn}
    </ul>
  );
};

ContactLinks.propTypes = {
  email: PropTypes.string,
  facebook: PropTypes.string,
  github: PropTypes.string,
  linkedin: PropTypes.string,
};
ContactLinks.defaultProps = {
  email: null,
  facebook: null,
  github: null,
  linkedin: null,
};

export default ContactLinks;
