import React, { Component } from 'react';
import PropTypes from 'prop-types';

// The Expandable component initially renders its children with a defined height if they
// would exceed it, then can then be expanded to 100% on request
class Expandable extends Component {
  static propTypes = {
    children: PropTypes.node,
    initialHeight: PropTypes.number, // in pixels
    language: PropTypes.string,
  }

  static defaultProps = {
    children: null,
    initialHeight: 400, // px
    language: 'en',
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      propsChanged: true,
    };
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeWindow);
  }

  /* eslint react/no-did-update-set-state: 0 */
  componentDidUpdate() {
    const { propsChanged } = this.state;
    const { initialHeight } = this.props;
    if (propsChanged && this.contentRef.current) {
      this.setState({
        propsChanged: false,
      });
      if (this.contentRef.current.clientHeight < initialHeight) {
        this.setState({ isExpanded: true });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow);
  }

  onResizeWindow = () => { // trigger re-render as content may now be taller
    this.setState({ propsChanged: true });
  }

  handleButtonClick = () => {
    this.setState({ isExpanded: true });
  }

  render() {
    const {
      children,
      initialHeight,
      language,
    } = this.props;
    const {
      isExpanded,
    } = this.state;
    const mainStyle = (isExpanded)
      ? {
        height: '100%',
      }
      : {
        maxHeight: `${initialHeight}px`,
      };
    return (
      <div className="expandable" style={mainStyle}>
        {(isExpanded)
          ? null
          : (
            <div className="expandable__button-container">
              <button
                className="expandable__button"
                type="button"
                onClick={this.handleButtonClick}
              >
                {(language === 'en') ? 'read more...' : 'čtěte dál...'}
              </button>
            </div>
          )}
        <div ref={this.contentRef}>
          {children}
        </div>
      </div>
    );
  }
}

export default Expandable;
